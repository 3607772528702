import {
	createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import api from './api'
import { createI18n } from 'vue-i18n'
import messages from './lang/index'

import public_config from '../config.js'

if(!localStorage.getItem('lang')){
	localStorage.setItem('lang', 'zh-Hans')
}

let i18nConfig = {
  locale: localStorage.getItem('lang'),
  messages
}

const i18n = new createI18n(i18nConfig)

const base_url = location.origin
const staticUrl = base_url

api.getSysInfo()

router.beforeEach((to, from, next) => {
	let url = location.href	
	let recode_split = url.split('recode=')
	
	if(recode_split.length > 1){
		let code_str = recode_split[1]
		localStorage.setItem('re_code', code_str)
	}
	
	const token = localStorage.getItem('token')
	if (to.meta.requiresAuth) {
		if(token){
			api.getUserInfo()
			next()
		}else{
			localStorage.removeItem('user_info');
			localStorage.removeItem('token');
			next({
				path: '/public_login'
			})
		}
	} else {
		next()
	}
})

async function start() {
	const app = createApp(App)

	//定义一个全局变量
	app.config.globalProperties.$api = api
	app.config.globalProperties.$base_url = base_url
	app.config.globalProperties.$staticUrl = staticUrl
	app.config.globalProperties.$page_intv = null

	app.use(router)
	
	app.use(i18n)

	app.mount('#app')
}
start()