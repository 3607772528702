import axios from 'axios';
import router from '../router'
import public_config from '../../config.js'

let base = location.origin + '/api.php'; //public_config.baseUrl;

// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(function(config) {
	config.headers.token = localStorage.getItem('token');
	config.headers.lang = localStorage.getItem('lang') ? localStorage.getItem('lang'):'zh-Hans';
	return config;
}, function(error) {
	return Promise.reject(error);
});

axios.interceptors.response.use(async (response) => {
	/* 请求之后拦截器。可以使用async await 做异步操作  */
	//console.log('服务器请求拦截发生', response)
	if (response.data.code >= 100) { // 服务端返回的状态码不等于200，则reject()
		localStorage.removeItem('user_info')
		localStorage.removeItem('token');
		router.push({
			path: '/public_login'
		})
		return response
	} else {
		return response
	}
}, (response) => { // 请求错误做点什么。可以使用async await 做异步操作
	return Promise.reject(response)
})

export const getSysInfo = params => {
	axios.get(`${base}/Public/getSysInfo`, params).then((res) => {
		if (res.data) {
			if (res.data.status) {
				console.log('系统信息', res.data)
				localStorage.setItem('system', JSON.stringify(res.data))
			}
		}
	})
};

export const checkAuth = params => {
	axios.get(`${base}/User/check`, params).then((res) => {
		//console.log(res)
	})
};

export const login = params => {
	return axios.post(`${base}/Public/login`, params).then(res => res.data);
};

export const register = params => {
	return axios.post(`${base}/Public/register`, params).then(res => res.data);
};

export const getSysLevel = params => {
	return axios.get(`${base}/Public/getSysLevel`, params).then(res => res.data);
};

export const getUserInfo = async params => {
	axios.get(`${base}/User/userInfo`, {
		params: params
	}).then(function(res) {
		if (res.data) {
			if (res.data.status) {
				localStorage.setItem('user_info', JSON.stringify(res.data))
			}
		}
	});
};

export const buy = params => {
	return axios.post(`${base}/Kuangji/zhiya`, params).then(res => res.data);
};

export const getWallet = async params => {
	return axios.get(`${base}/User/getPayment`, params).then(res => res.data);
};
export const modWallet = async params => {
	return axios.post(`${base}/User/modPayment`, params).then(res => res.data);
};
export const cash = params => {
	return axios.post(`${base}/Account/cash`, params).then(res => res.data);
};
export const addMoney = params => {
	return axios.post(`${base}/Account/addMoney`, params).then(res => res.data);
};
export const tibiRecord = params => {
	return axios.post(`${base}/Account/tibiRecord`, params).then(res => res.data);
};
export const getCashEdu = params => {
	return axios.get(`${base}/Account/cashEdu`, params).then(res => res.data);
};
export const getHistory = params => {
	return axios.post(`${base}/Account/history`, params).then(res => res.data);
};
export const tradeBuy = params => {
	return axios.post(`${base}/Trade/buy`, params).then(res => res.data);
};
export const tradeSell = params => {
	return axios.post(`${base}/Trade/sell`, params).then(res => res.data);
};
export const exchange = params => {
	return axios.post(`${base}/Account/exchange`, params).then(res => res.data);
};
export const getSon = params => {
	return axios.post(`${base}/User/getSon`, params).then(res => res.data);
};
export const nodeTree = params => {
	return axios.post(`${base}/User/nodeTree`, params).then(res => res.data);
};
export const getLiquidity = params => {
	return axios.get(`${base}/Kuangji/getZhiya`, params).then(res => res.data);
};
export const getPriceData = params => {
	return axios.get(`${base}/Public/getPriceData`, params).then(res => res.data);
};
export const getUplevel = params => {
	return axios.get(`${base}/User/getUplevel`, params).then(res => res.data);
};
export const getChongzhiHistory = params => {
	return axios.post(`${base}/Account/addMoneyRecord`, params).then(res => res.data);
};
export const uploadFile = params => {
	return axios.post(`${base}/Public/uploadBase64`, params).then(res => res.data);
};
export const updateCer = params => {
	return axios.post(`${base}/Account/updateCer`, params).then(res => res.data);
};
export const sendMail = params => {
	return axios.post(`${base}/Mail/sendMail`, params).then(res => res.data);
};
export const getMail = params => {
	return axios.get(`${base}/Mail/getMail`, params).then(res => res.data);
};
export const modUserInfo = params => {
	return axios.post(`${base}/User/modUserInfo`, params).then(res => res.data);
};
export const modPwd = params => {
	return axios.post(`${base}/User/modPwd`, params).then(res => res.data);
};
export const findBackPwd = params => {
	return axios.post(`${base}/Public/findBackPwd`, params).then(res => res.data);
};
export const getNews = params => {
	return axios.get(`${base}/News/index`, params).then(res => res.data);
};
export const sendCode = params => {
	return axios.post(`${base}/Public/sendVer`, params).then(res => res.data);
};
export const getJingtaiTongji = params => {
	return axios.get(`${base}/Public/jingtaiTongji`, params).then(res => res.data);
};