import { createRouter, createWebHashHistory } from 'vue-router'

export default createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/',
			name: '/',
			component: () => import('../views/layout/App.vue'),
			children: [
				{
					path: '/',
					name: 'HomeIndex',
					component: () => import('../views/home/Index.vue'),
				},{
					path: '/user_index',
					name: 'UserIndex',
					meta: { requiresAuth: true },
					component: () => import('../views/user/Index.vue'),
				},{
					path: '/public_login',
					name: 'PublicLogin',
					component: () => import('../views/public/Login.vue'),
				},{
					path: '/public_register',
					name: 'PublicRegister',
					component: () => import('../views/public/Register.vue'),
				},{
					path: '/public_forget_pwd',
					name: 'PublicForgetPwd',
					component: () => import('../views/public/ForgetPwd.vue'),
				},{
					path: '/user_share',
					name: 'UserShare',
					meta: { requiresAuth: true },
					component: () => import('../views/user/Share.vue'),
				},{
					path: '/account_index',
					name: 'AccountIndex',
					meta: { requiresAuth: true },
					component: () => import('../views/account/Index.vue'),
				},{
					path: '/liquidity_index',
					name: 'LiquidityIndex',
					meta: { requiresAuth: true },
					component: () => import('../views/liquidity/Index.vue'),
				},{
					path: '/home_fast',
					name: 'HomeFast',
					component: () => import('../views/home/Fast.vue'),
				},{
					path: '/home_news',
					name: 'HomeNews',
					component: () => import('../views/home/News.vue'),
				},{
					path: '/home_lianmeng',
					name: 'HomeLianmeng',
					component: () => import('../views/home/Lianmeng.vue'),
				},{
					path: '/home_lianmeng',
					name: 'HomeLianmeng',
					component: () => import('../views/home/Lianmeng.vue'),
				},{
					path: '/home_about',
					name: 'HomeAbout',
					component: () => import('../views/home/About.vue'),
				},{
					path: '/home_luxian',
					name: 'HomeLuxian',
					component: () => import('../views/home/Luxian.vue'),
				},{
					path: '/user_mail',
					name: 'UserMail',
					meta: { requiresAuth: true },
					component: () => import('../views/user/Mail.vue'),
				},{
					path: '/home_jishu',
					name: 'HomeJishu',
					component: () => import('../views/home/Jishu.vue'),
				},{
					path: '/home_wangluo',
					name: 'HomeWangluo',
					component: () => import('../views/home/Wangluo.vue'),
				},{
					path: '/home_zhishu',
					name: 'HomeZhishu',
					component: () => import('../views/home/Zhishu.vue'),
				},{
					path: '/home_yuzhou',
					name: 'HomeYuzhou',
					component: () => import('../views/home/Yuzhou.vue'),
				},
			]
		}
	]
})