<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
	 @import url('./assets/css/app.css');
	html {
	  font-size: 90px;
	}
	@media screen and (max-width: 600px) {
	    html {
	        font-size: 90px;
	    }
	}
	
	@media screen and (max-width: 525px) {
	    html {
	        font-size: 80px;
	    }
	}
	
	@media screen and (max-width: 450px) {
	    html {
	        font-size: 70px;
	    }
	}
	
	@media screen and (max-width: 415px) {
	    html {
	        font-size: 65.2px;
	    }
	}
	
	@media screen and (max-width: 375px) {
	    html {
	        font-size: 60px;
	    }
	}
	
	@media screen and (max-width: 360px) {
	    html {
	        font-size: 58px;
	    }
	}
	
	@media screen and (max-width: 320px) {
	    html {
	        font-size: 55.6667px;
	    }
	}
	
	body {
	    font-family: "sans-serif", "Clockopia", "arial";
	    margin: 0 auto;
	    font-size: 0.18rem;
		background: #0f0f0f;
		color: #fff;
	}
	*{
		box-sizing: border-box;
		margin: 0;
		padding: 0;
	}
	button{
		background: none;
		border: none;
	}
	ul,li{
		list-style: none;
	}
	a{
		text-decoration: none;
	}
</style>
